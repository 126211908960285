<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h" style="display: inline-block">
              <span> {{ $t("message.reception_visit") }}</span>
            </div>
            <div style="display: inline-block">
              <span class="ml-4">
                <el-tag class="mr-2">{{ $t("message.quantity") }}: {{ list.length }}</el-tag>
                <el-tag class="mr-2" type="success">{{ $t("message.total_amount") }}:
                  {{ Math.round(totalAmount) | formatMoney }}</el-tag>
                <el-tag>{{ $t("message.id_number") }}:
                  {{ this.old_order ? this.old_order.id : "" }}</el-tag>
              </span>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
<!--              <el-button :loading="waitingPrint" :disabled="saveBtn" @click="printDouble(form.id)">{{-->
<!--                $t("message.print_double") }}</el-button>-->
              <el-button :loading="waitingPrint || waiting" @click="print(form.id)">{{ $t("message.print") }}</el-button>
              <el-button :loading="waiting" @click="submit()">{{
                $t("message.save")
              }}</el-button>
              <el-button :loading="waiting" @click="close()">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-tabs class="theePopup" v-model="activeTab" v-loading="waiting">
      <el-tab-pane :label="$t('message.basic_information')" name="first">
        <el-row>
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="grid-content">
              <div class="add_patient">
                <el-form ref="form" class="from_x">
                  <el-form-item>
                    <el-input v-model="search" @input="searchPatients" clearable></el-input>
                  </el-form-item>
                </el-form>
                <div class="old_patient" v-if="yes_user">
                  <div class="info_old_patient action">
                    <a>
                      <span>{{ user.surname }} {{ user.first_name }}
                        {{ user.patronymic }}</span>
                      <span class="tel_infos">
                        <i class="flaticon-call mr-1"></i>
                        {{ user.phone }}</span>
                    </a>
                    <a class="icon_ad" @click="openUpdatePatient(user)">
                      <i class="flaticon-report-1"></i>
                    </a>
                  </div>
                </div>
                <template v-else>
                  <div class="old_patient" v-for="patient in patients" :key="patient.id">
                    <div class="info_old_patient">
                      <a @click="edit(patient)">
                        <span>{{ patient.surname }} {{ patient.first_name }}
                          {{ patient.patronymic }}</span>
                        <span class="tel_infos">
                          <i class="flaticon-call mr-1"></i>
                          {{ patient.phone }}</span>
                      </a>
                      <a class="icon_ad" @click="openUpdatePatient(patient)">
                        <i class="flaticon-report-1"></i>
                      </a>
                    </div>
                  </div>
                </template>
                <button class="add_pats" @click="addPatient()">
                  {{ $t("message.add_new_patient") }}
                </button>
              </div>
            </div>
          </el-col>

          <template>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="grid-content">
                <div class="add_patient">
                  <div class="title_cet mb-3">
                    {{ $t("message.from_hours") }}
                  </div>
                  <el-time-select v-model="form.from_hour" :picker-options="{
                    start: hours[0].time,
                    step: '00:10',
                    end: tenMinMin(hours[hours.length - 1].time),
                  }" placeholder="время начинать">
                  </el-time-select>

                  <div class="time_of_receipt mt-4">
                    <span class="demonstration d-block mb-2">{{
                      $t("message.duration")
                    }}</span>
                    <el-radio-group v-model="form.duration">
                      <el-radio label="10">10 мин.</el-radio>
                      <el-radio label="20">20 мин.</el-radio>
                      <el-radio label="30">30 мин.</el-radio>
                      <el-radio label="40">40 мин.</el-radio>
                      <el-radio label="50">50 мин.</el-radio>
                      <el-radio label="60">60 мин.</el-radio>
                      <el-radio label="90">90 мин.</el-radio>
                      <el-radio label="120">120 мин.</el-radio>
                    </el-radio-group>
                  </div>

                  <div class="textarea mt-4">
                    <div class="sub-title">{{ $t("message.notes") }}</div>

                    <el-input type="textarea" v-model="form.notes" maxlength="30" show-word-limit>
                    </el-input>
                  </div>
                </div>
              </div>
            </el-col>

            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="grid-content">
                <div class="add_patient">
                  <div class="name-doctor">
                    <a href="#" class="name">{{ doctor.name }}</a>
                    <span v-for="specialty in doctor.specialties" :key="specialty.id" class="speciality">
                      {{ specialty }}
                    </span>
                  </div>

                  <div class="new_room mb-4">
                    <div class="title_new_room">
                      {{ $t("message.cabinet") }}:
                    </div>
                    <div class="room_number_title" v-for="item in room" :key="item.id">
                      {{ item.room.room_number }}
                    </div>
                  </div>

                  <div class="title_cet mb_10">
                    {{ $t("message.condition") }}
                  </div>

                  <div class="radioMarka">
                    <el-radio-group v-model="form.condition_id">
                      <el-radio v-for="condition in conditions" :key="condition.id"
                        :disabled="condition.status === 'cancel'" :label="condition.id">{{ condition.name }}</el-radio>
                    </el-radio-group>
                  </div>
                </div>
              </div>
            </el-col>
          </template>
        </el-row>
      </el-tab-pane>

      <el-tab-pane :label="$t('message.service')" name="second">
        <el-row class="p20">
          <el-col :xs="24" :sm="15" :md="15" :lg="15" :xl="15">
            <el-select v-model="patientServiceId" filterable :placeholder="$t('message.start_input_find_service')"
              class="autoInput" clearable>
              <template v-for="serviceParent in servicesSelected">
                <el-option-group v-if="serviceParent.children.length > 0" :key="serviceParent.id"
                  :label="serviceParent.name">
                  <el-option v-for="service in getChildrens(serviceParent.children)" :key="service.id"
                    :label="service.name" :value="service.id">
                  </el-option>
                </el-option-group>
                <el-option-group v-else-if="serviceParent.is_service == true &&
                  serviceParent.parent_id == null
                  " :key="serviceParent.id">
                  <el-option :label="serviceParent.name" :value="serviceParent.id">
                  </el-option>
                </el-option-group>
              </template>
            </el-select>
          </el-col>

          <el-col :xs="24" :sm="9" :md="9" :lg="9" :xl="9">
            <el-button class="mb-sm-1" style="font-size: 14px !important; padding: 12px !important; margin-left: 10px;"
              @click="addService">{{ $t("message.add") }}</el-button>
            <el-button style="font-size: 14px !important; padding: 12px !important"
              @click="drawerPatientService = true">{{ $t("message.add_service") }}</el-button>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <table class="axad table table-striped table-bordered mt-4">
              <thead>
                <tr>
                  <th>{{ $t("message.pp") }}</th>
                  <th class="wi-50">{{ $t("message.name") }}</th>
                  <th class="wi-7">{{ $t("message.quantity_short") }}</th>
                  <th class="wi-7">{{ $t("message.price2") }}</th>
                  <th class="wi-7">{{ $t("message.discount_percent") }}</th>
                  <th class="wi-7">{{ $t("message.discount_price") }}</th>
                  <th class="wi-7">{{ $t("message.amount") }}</th>
                  <th class="wi-7">{{ $t("message.delete") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(ser, index) in list" :key="'ser.id33' + index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ ser.name }}
                  </td>
                  <td>
                    <el-input type="number" id="usr" step="any" @input="editRow(ser, $event, 'count')" :value="ser.count"
                      :min="1"></el-input>
                  </td>
                  <td>
                    {{ ser.price | formatMoney }}
                  </td>
                  <td>
                    <el-input type="number" step="any" @input="editRow(ser, $event, 'discount_price')"
                      :value="ser.discount_price" :min="0" :max="100"></el-input>
                  </td>
                  <td>
                    {{
                      Math.round((ser.discount_price * ser.price) / 100)
                      | formatMoney
                    }}
                  </td>
                  <td>
                    {{
                      Math.round(
                        ser.count *
                        (ser.price - (ser.price * ser.discount_price) / 100)
                      ) | formatMoney
                    }}
                  </td>
                  <td>
                    <el-button class="d-block" type="danger" icon="el-icon-delete" circle
                      @click.native.prevent="deleteRow(ser)">
                    </el-button>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <th colspan="5">{{ $t("message.total_0") }}</th>
                <th class="w-7">{{ totalDiscount | formatMoney }}</th>
                <th colspan="2">{{ Math.round(totalAmount) | formatMoney }}</th>
              </tfoot>
            </table>
          </el-col>
        </el-row>

        <!-- <div class="user-kod">
          <div class="title_cet">{{ $t("message.from_whom") }}</div>
          <div class="user-kod-top">
            <el-select
              class="autoInput"
              v-model="form.partner_doctor_id"
              filterable
              clearable
              :placeholder="$t('message.partner_doctor')"
            >
              <el-option
                v-for="item in partnerDoctors"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <button class="add_pats" @click="drawerPartner = true">
            {{ $t("message.add_new_partner_doctor") }}
          </button>
        </div> -->
        <el-row :gutter="25" class="p20 my-1">
          <!-- <el-col :sm="12">
            <el-form ref="form">
              <div class="title_cet">{{ $t("message.material") }}</div>

              <el-select
                class="autoInput"
                v-model="form.material_id"
                filterable
                clearable
                :placeholder="$t('message.material')"
              >
                <el-option
                  v-for="item in materials"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form>
          </el-col> -->
          <el-col :sm="12">
            <el-form ref="form">
              <div class="title_cet">{{ $t("message.clinic_diagnos") }}</div>
              <el-input v-model="form.diagnos" :placeholder="$t('message.clinic_diagnos')"></el-input>
            </el-form>
          </el-col>
          <!-- <el-col :sm="12">
            <el-form ref="form">
              <div class="title_cet">{{ "Блок" }}</div>
              <el-input v-model="form.blok" placeholder="Блок"></el-input>
            </el-form>
          </el-col>
          <el-col :sm="12">
            <el-form ref="form">
              <div class="title_cet">{{ "Количество флаконов" }}</div>
              <el-input
                v-model="form.vial_quantity"
                placeholder="Количество флаконов"
              ></el-input>
            </el-form>
          </el-col>

          <el-col :sm="12">
            <el-form ref="form">
              <div class="title_cet">{{ $t("message.glass") }}</div>
              <el-input
                v-model="form.glass"
                :placeholder="$t('message.glass')"
              ></el-input>
            </el-form>
          </el-col> -->

          <el-col :sm="12">
            <el-form ref="form">
              <div class="title_cet">{{ $t("message.outpatient_card") }}</div>
              <el-input v-model="form.outpatient_card" :placeholder="$t('message.outpatient_card')"></el-input>
            </el-form>
          </el-col>
        </el-row>
        <el-row :gutter="25" class="p20">
          <el-col :sm="8">
            <div class="my-100">
              <div class="title_cet">{{ $t("message.partner_clinic") }}</div>
              <el-form ref="form" status-icon :model="form" :rules="rules">
                <el-form-item>
                  <el-select class="autoInput" v-model="form.partner_clinic_id" filterable clearable
                    @change="filterDepartment" :placeholder="$t('message.partner_clinic')">
                    <el-option v-for="item in partnerClinics" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>

              <!-- <button class="add_pats" @click="drawerPartnerClinic = true">
                {{ $t("message.add_new_partner_clinic") }}
              </button> -->
            </div>
          </el-col>

          <el-col :sm="8">
            <div class="my-100">
              <div class="title_cet">{{ $t("message.department") }}</div>
              <el-select class="autoInput" v-model="form.department_id" filterable clearable
                :placeholder="$t('message.department')">
                <el-option v-for="item in departments" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>

              <button class="add_pats" @click="drawerDepartment = true">
                {{ $t("message.add_new_department") }}
              </button>
            </div>
          </el-col>

          <el-col :sm="8">
            <div class="my-100">
              <div class="title_cet">{{ $t("message.partner_doctor") }}</div>

              <partner-doctors :size="'large'" v-model="form.partner_doctor_id"
                :partner_clinic_id="form.partner_clinic_id"></partner-doctors>

              <button class="add_pats" @click="drawerPartnerDoctor = true">
                {{ $t("message.add_new_partner_doctor") }}
              </button>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>

    <el-drawer class="popups_title" :append-to-body="true" :visible.sync="drawerPatientService" direction="rtl" size="70%"
      :wrapperClosable="false">
      <CreatePatientService :driver="drawerPatientService" @c-close="closeDrawerPatientService"></CreatePatientService>
    </el-drawer>

    <el-drawer class="popups_title" :append-to-body="true" :visible.sync="drawerUpdatePatient" direction="rtl" size="60%"
      :wrapperClosable="false">
      <UpdatePatient @c-close="closeDrawerUpdatePatient" :patient="selectedPatient" :open="drawerUpdatePatient"
        drawer="drawerUpdatePatient"></UpdatePatient>
    </el-drawer>

    <el-drawer class="popups_title" :append-to-body="true" :visible.sync="drawerPartner" direction="rtl" size="40%"
      :wrapperClosable="false">
      <AddPartnerDoctor @c-close="closeDrawerAddPartnerDoctor"></AddPartnerDoctor>
    </el-drawer>

    <el-drawer class="popups_title" :append-to-body="true" :visible.sync="drawerCreatePatient" direction="rtl" size="60%"
      :wrapperClosable="false">
      <CreatePatient @c-close="closeDrawerPatient" drawer="drawerCreatePatient"></CreatePatient>
    </el-drawer>

    <el-drawer class="popups_title" :wrapperClosable="false" :append-to-body="true" :visible.sync="drawerPatientService"
      direction="rtl" size="85%" :ref="serviceDrawer.create.name" @opened="drawerOpened(serviceDrawer.create.component)"
      @closed="drawerClosed(serviceDrawer.create.component)">
      <CreatePatientService :ref="serviceDrawer.create.component" :driver="drawerPatientService" @items="itemsChanged"
        @c-close="closeDrawerPatientService"></CreatePatientService>
    </el-drawer>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import drawer from "@/utils/mixins/drawer";
import partnerDoctors from "@/components/inventory-select/partner-doctor";
import order from "@/utils/mixins/order-with-doctor";

export default {
  mixins: [order, drawer],
  components: {
    partnerDoctors,
  },
  props: {
    doctor: { type: Object, required: true },
    order,
    selectedItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingButton: false,
      loadingPrintButton: false,
      modalAticler: false,
      room: null,
      old_order: {},
      reopenUpdate: false,
      serviceDrawer: {
        create: {
          name: "create",
          status: false,
          component: "componentDrawerCreate",
        },
      },
    };
  },
  watch: {
    open: {
      handler: async function (newVal, oldVal) {
        if (newVal === true) {
          await this.reLoadModel();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    if (this.hours && this.hours.length === 0) this.loadHours();
  },
  computed: {
    ...mapGetters({
      rules: "orders/rules",
      model: "orders/relation_model",
      hours: "hours/list",
      showModel: "orders/model",
    }),
  },
  methods: {
    ...mapActions({
      editPatient: "patients/show",
      loadHours: "hours/index",
      editService: "services/show",
      updateSetlist: "services/updateSetlist",
      save: "orders/update",
      showModelUpdate: "orders/relationShow",
    }),
    itemsChanged(val) {
      this.items = JSON.parse(JSON.stringify(val));
    },
    filterDepartment(val) {
      const query = { partner_clinic_id: val };
      this.loadDepartments(query).then((res) => {
        if (!res.data.departments.data.length) this.form.department_id = null;
      });
    },
    afterOpen() {
      if (this.doctor.work_times.length > 1) {
        this.room = this.doctor.work_times.filter(
          (val) =>
            val.from <= this.selectedItem.from &&
            val.to > this.selectedItem.from
        );
        this.room.length = 1;
      } else {
        this.room = this.doctor.work_times;
      }
    },
    updatingOrder(close) {
      this.changeWaiting(true);
      this.form.id = this.old_order.id;
      this.form.patient_id = this.user.id;
      this.form.services = this.items;

      this.update(this.form)
        .then((res) => {
          this.changeWaiting();
          this.saveBtn = false;
          this.printModel = res.data ? res.data.order : null;
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
        })
        .catch((err) => {
          this.changeWaiting();
          this.ifValidationError(err);
        });
    },

    expressPrice(is_express) {
      if (is_express) {
        return 1 + this.express[0].percent / 100;
      } else {
        return 1;
      }
    },
    submit(close) {
      this.changeWaiting(true);
      this.form.services = this.list;
      this.form.patient_id = this.user.id;
      this.form.doctor_id = this.doctor.id;

      this.save(this.form)
        .then((res) => {
          this.changeWaiting();
          this.old_order = res.data.order;
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.calendarChanged();
        })
        .catch((err) => {
          this.changeWaiting();
          this.ifValidationError(err);
        });
    },
    reLoadModel() {
      const query = { id: this.order ? this.order.id : null, relation: true };
      this.changeWaiting(true);
      this.showModelUpdate(query)
        .then((res) => {
          let modelData = JSON.parse(JSON.stringify(this.model));
          delete modelData.room;
          delete modelData.room_id;
          this.form = modelData;
          this.user = this.form ? this.form.patient : {};
          this.noUser(true);
          this.changeWaiting();
          this.form.day = moment(this.today).format("YYYY-MM-DD");
          this.updateSetlist(
            JSON.parse(JSON.stringify(this.model.order_services))
          )
            .then((res) => { })
            .catch((err) => { });
        })
        .catch((err) => {
          this.changeWaiting();
        });
    },
    empty() {
      this.activeTab = "first";
      this.emptyList();
      this.emptySearch();
      this.form = {};
      this.old_order = {};
      this.user = {};
      this.noUser(true);
      this.patientServiceId = "";
      this.search = "";
    },
  },
};
</script>